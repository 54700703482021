@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html[lang="en"] [lang="tr"],
  html[lang="tr"] [lang="en"] {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    /* background-color: black;*/
    @apply tw-bg-potters-clay;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px gray;
    background-color: black;
  }
}
